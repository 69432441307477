.mian-menu-top .modal-dialog {
    min-width: 100%;
    min-height: 100vh;
    margin: 0px;
    border-radius: 0px !important;
}
.mian-menu-top .modal-dialog .modal-content {
    width: 100%;
    min-height: 100vh;
    border-radius: 0px;
    background-image: url(../../assets/images/bg/menu-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.mian-menu-top  button.btn.btn-secondary {
    position: absolute;
    right: 100px;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}
.mian-menu-top  ul li span, .mian-menu-top  ul  li a{
    color: #9B9A9A;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 66.574px;
    /* margin-left: 100px; */
    margin-left: 10px;
}
.mian-menu-top  ul li a.dropdown {
    /* color: #95d048; */
    background-repeat: no-repeat;
    background-position: calc(100%) 14px;
    padding-right: 32px;
    background-image: url(../../assets/images/icon/drop.png);
}
.mian-menu-top  ul  li a:hover{
    color: #fff;
    font-weight: 500;
}
.mian-menu-top  ul  li ul {
    padding-left: 114px !important;
}
.tm-img{
    width: 29px;
    margin-top: -10px;
}
@media(max-width:574.99px){
    .mian-menu-top  ul li span, .mian-menu-top  ul  li a {
        margin-left: 0;
    }
    .mian-menu-top  button.btn.btn-secondary {
        right: 40px;
    }
}