.appService {
    position: relative;
}
.appService-tab .nav-item a{
    border-radius: 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 49.5px;
    color: #FFF;
    background-color: #3F3F3F;
 }
 .appService-tab .nav-item a.active{
     color: #3F3F3F;
     background-color: #D9D9D9;
     font-weight: 700;
 }
 .appService-tab  .nav-item {
    width: 50%;
    text-align: center;
    max-width: 330px;
    z-index: 9;
    
}
.min-tab-contain .tab-content {
    max-width: 880px;
    margin: 0 auto;
    border: 4px solid transparent;
    position: relative;
    height: 398px;
    background-image: url(../../assets/images/bg/border-bg.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: -8px;
    position: relative;
}
img.ser-left {
    position: absolute;
    bottom: -90px;
    left: -200px;
    /* text-shadow: 0px 0px 80px; */
    padding: 12px;
    pointer-events: none;
}
img.slf2 {
    position: absolute;
    top: -200px;
    left: -200px;
    /* width: 500px; */
    z-index: 9;
    pointer-events: none;
}
.min-tab-contain h2.subtitle {
    position: absolute;
    left: -200px;
    max-width: 400px;
    text-align: left;
    top: 100px;
    background: #191919;
    padding-bottom: 33px;
}
.light .min-tab-contain h2.subtitle{
    background: #fff;
}
.appService-tab .min-tab-contain .tab-pane {
    margin-right: -200px;
    padding-left: 300px;
    padding-top: 30px;
}
section.appService-galleryimg {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
section.appService-galleryimg .asglist {
    position: relative;
    padding: 12px;
    width: 50%;
}
section.appService-galleryimg .asglist h4 {
    color: #fff;
    position: absolute;
    top: calc(50% - 8px);
    text-align: center;
    width: calc(100% - 24px);
    left: 0;
    font-size: 30.278px;
font-style: normal;
font-weight: 600;
line-height: 22.369px; 
}

span.counter {
    position: absolute;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.58px;
    color: #fff;
    transform: rotate(90deg);
    left: 8%;
    top: 0;
    filter: brightness(0.5);
}

span.counter::after {
    position: absolute;
    content: '';
    height: 2px;
    right: -59px;
    bottom: 10px;
    width: 48px;
    background-color: #fff;
}

@media(max-width:1365.99px){
    .appService-tab .min-tab-contain .tab-pane {
        margin-right: -140px;
        padding-left: 260px;
    }
    .min-tab-contain h2.subtitle {
        left: -130px;
    }
    img.ser-left {
        left: -181px;
        max-width: 522px;
    }
}
@media(max-width:1199.99px){
    .min-tab-contain h2.subtitle {
        left: -78px;
        font-size: 40px;
        max-width: 350px;
    }
    .appService-tab .min-tab-contain .tab-pane {
        margin-right: -80px;
    }
}

@media(max-width:1079.99px){
    .min-tab-contain h2.subtitle {
        left: -30px;
        font-size: 30px;
        max-width: 280px;
    }
    .appService-tab .min-tab-contain .tab-pane {
        margin-right: -40px;
    }
    section.appService-galleryimg .asglist img{
        width: 100%;
    }
    img.ser-left {
        left: -193px;
        max-width: 484px;
        bottom: -67px;
    }
}
@media(max-width:767.99px){
    .appService-tab .min-tab-contain .tab-pane {
        margin-right: -16px;
    }
    section.appService-galleryimg .asglist h4 {
        width: calc(100% - 12px);
        font-size: 12px;
    }
    .min-tab-contain h2.subtitle {
        left: -0px;
        font-size: 24px;
        max-width: 230px;
        line-height: 32px;
    }
    img.ser-left {
        left: -113px;
        max-width: 300px;
        bottom: 55px;
    }
    .Servicesage {
        padding-bottom: 20px !important;
    }
    span.counter {

        left: 8px;
        top: -10px;
    }
}
@media(max-width:479.99px){
    section.appService-galleryimg .asglist {
        position: relative;
        padding: 0px;
        width: calc(100% / 3);
    }
    .appService-tab .min-tab-contain .tab-pane {
        margin-right: -16px;
        padding-left: 9px;
    }
}