header.top-header {
    position: fixed;
    z-index: 99;
    width: 100%;
}

img.logo {
    margin-top: 30px;
}

button.btn.seme-btn {
    border-radius: 0px;
    height: 102px;
    width: 92px;
    border: none;
    outline: none;
    box-shadow: none;
    padding-top: 20px;
}

button.menu-button.btn:focus,
button.menu-button.btn:hover,
button.menu-button.btn {
    background: #fff;


}

button.search-button.btn:hover,
button.search-button.btn:focus,
button.search-button.btn {
    background: #474747;
}

a.login-register-btn.btn {
    margin: 0px 4px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.16px;
    border-radius: 0px;
    color: #fff;
    padding: 4px 24px;
    margin-top: 12px;
}

a.login-register-btn.btn.active {
    color: #474747;
    background-color: #fff;
    border: none;
}

.active~a.login-register-btn.btn:hover {
    color: #474747;
    background-color: #fff;
}

header.top-header.header-black {
    background: #2F2F2F;
}

header.top-header.header-black img.logo {
    margin-top: 10px;
}


@media(max-width:767px) {
    button.btn.seme-btn {
        height: 90px;
        width: 60px;
    }
}

@media(max-width:574.99px) {
    img.logo {
        max-width: 100%;
        margin-top: 16px;
    }

    a.login-register-btn.btn {
        padding: 4px 10px;
    }

    button.btn.seme-btn {
        height: 90px;
        width: 60px;
    }
}

@media(max-width:479.99px) {
    a.login-register-btn.btn {
        padding: 4px 8px;
        font-size: 12px;
    }

    button.btn.seme-btn {
        height: 55px;
        width: 49px;
        padding-top: 12px;
    }

    .mian-menu-top ul li ul {
        padding-left: 30px !important;
    }
}