.for-navlink a{
    font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 32px;
padding: 4px 12px;
}
.socil-navlink a {
    display: inline-block;
    margin: 4px;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 50%;
    line-height: 38px;
    padding: 10px;
}
.copy-right-text.w-100{
    border-top: 1px solid #3F3F3F;
    padding: 12px;
    margin-top: 12px;
}

@media(max-width:479.99px){
    .for-navlink a {
        font-size: 12px;
        padding: 4px 12px;
        display: inline-block;
    }
    .socil-navlink a {
        margin: 4px;
        width: 50px;
        height: 50px;
        line-height: 28px;
        padding: 10px;
    }
    .socil-navlink a img {
        width: auto;
        max-width: 16px;
    }
}