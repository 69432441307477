/* All common css */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

body {
    color: #fff;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a,
a[href] {
    text-decoration: none;
    outline: none;
}

ul,
lo,
li {
    margin: 0;
    padding: 0 !important;
    list-style: none;
}

h2.subtitle {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 49.5px;
}

h2.subtitle span {
    font-weight: 300;
}


/* This is for dark and light theme */
/* font color for light */
.light .text-color-white {
    color: #ffff !important;
}

.light .text-color-gray {
    color: #3D3D3D !important;
}

/* font color for light end*/
/* font color for dark */
.dark .text-color-white {
    color: #3D3D3D !important;
}

.dark .text-color-gray {
    color: #fff !important;
}

/* font color for dark end*/
/* background color for light */
.light .back-color-gray,
.light.back-color-gray {
    background-color: #191919 !important;
}

.light .back-color-white,
.light.back-color-white {
    background-color: #fff !important;
}

.light .back-color-lgray,
.light.back-color-lgray {
    background-color: #616161 !important;
}

/* background color for light end*/
/* background color for dark */
.dark.back-color-white {
    background-color: #191919 !important;
}

.dark .back-color-gray,
.dark.back-color-gray {
    background-color: #fff !important;
}

.dark .back-color-lgray,
.dark.back-color-lgray {
    background-color: #3f3f3f !important;
}

footer.light a.back-color-lgray {
    background-color: #d9d9d9 !important;
}

footer.light a.back-color-lgray img {
    filter: brightness(0);
}

footer.light a img.logo {
    filter: brightness(0.6);
}

.clientfeatures.light .list-card .card {
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
}

.clientfeatures.light .list-card .card img {
    filter: brightness(0);
}

.clientfeatures.light .list-card .card h3 {
    color: #3D3D3D;
}

.clientfeatures.light .list-card .card p {
    color: #616161;
}

/* background color for dark end*/

/* responsive */
@media(max-width:479.99px) {
    h2.subtitle {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40.5px;
    }

    .mian-menu-top ul li span,
    .mian-menu-top ul li a {
        font-size: 18px !important;
    }
}