.dark.requestform-app {
    background-color: #352E2E;
}

.light.requestform-app {
    background-color: #d9d9d9;
}

.requestform-app {
    position: relative;
}

.requestform-app .form-control {
    border-radius: 0px;
    margin-bottom: 30px !important;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    min-height: 48px;
    padding-left: 0px;
}

.dark.requestform-app .form-control {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.light.requestform-app .form-control {
    color: #616161;
    border-bottom: 1px solid #616161;
}

.dark.requestform-app .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #fff;
}

.dark.requestform-app .form-control::placeholder {
    color: #fff;
}

.light.requestform-app .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #616161;
}

.light.requestform-app .form-control::placeholder {
    color: #616161;
}

section.requestform-app img {
    position: absolute;
    top: 0px;
    right: 0;
}

section.requestform-app button.btn.btn-primary {
    width: 160px;
    border-radius: 0px;

    border: none;
    padding: 10px;
    font-weight: 500;
}

section.requestform-app.dark button.btn.btn-primary {
    background: #fff;
    color: #3e3e3e;
}

section.requestform-app.light button.btn.btn-primary {
    background: #3e3e3e;
    color: #fff;
}

.Attachment {
    position: relative;
}

.Attachment label.form-label {
    color: #fff;
    height: 48px;
    border-bottom: 1px solid;
    width: 100%;
    margin: 0;
    line-height: 48px;
}

.Attachment .form-control {
    position: absolute;
    top: 0px;
    opacity: 0;
}

.dark.requestform-app .form-control~span {
    position: absolute;
    right: 0;
    border: 2px solid;
    top: 0;
    color: #fff;
    padding: 5px 16px;
    pointer-events: none;
}

@media(max-width:1079.99px) {
    section.requestform-app img {
        max-width: 500px;
    }
}

@media(max-width:991.99px) {
    section.requestform-app img {
        max-width: 320px;
    }
}

@media(max-width:574.99px) {
    section.requestform-app img {
        max-width: 190px;
        top: 120px;
    }
}