.getinTouch-app.getintuchpage img {
    top: 60px;
}
.getintuchpage span.count-getinTouch {
    display: none;
}

@media(max-width:479.99px){
    .getinTouch-app.getintuchpage img {
        top: 6px;
        width: 140px;
    }
}