.app-features h2 span {
    display: block;
}

.app-features-tab-btn .nav-item {
    width: 50%;
    text-align: center;
}

.app-features-tab-btn .nav-item a {

    border-radius: 0px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 49.5px;
    color: #FFF;
    background-color: #3F3F3F;
}

.app-features-tab-btn .nav-item a.active {
    color: #3F3F3F;
    background-color: #D9D9D9;
    font-weight: 700;
}

.app-feature-item {
    width: 100%;
    background-color: #3F3F3F;
    background-image: url(../../assets/images/bg/app-feature-item-bg.png);
    padding: 70px 30px;
    background-size: cover;
    background-repeat: no-repeat;
}

.app-feature-item img {
    max-width: 45px;
    height: 45px;
    object-fit: contain;
    margin-bottom: 65px;
}

.app-feature-item h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.5px;
    color: #fff;
}

.app-feature-item p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    min-height: 75px;
}

.app-feature-list .owl-nav {
    text-align: right !important;
    margin: -5px !important;
}

.app-feature-list .owl-nav button.owl-prev {
    margin: 0px !important;

}

.app-feature-list .owl-nav button.owl-prev:hover,
.app-feature-list .owl-nav button.owl-next:focus,
.app-feature-list .owl-nav button.owl-next:hover,
.app-feature-list .owl-nav button.owl-prev:focus {
    background-color: transparent !important;
}

.app-feature-list .owl-nav .left-arrow,
.app-feature-list .owl-nav .right-arrow {
    height: 80px;
    width: 150px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    cursor: pointer;
}

.app-feature-list .owl-nav .right-arrow {
    background-image: url(../../assets/images/icon/arrow-right.svg);
    background-color: #3a4242;
}

.app-feature-list .owl-nav .left-arrow {
    background-image: url(../../assets/images/icon/arrow-left.svg);
    filter: brightness(0.5);
}

.app-feature-list .owl-nav .right-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    background: transparent;
    width: 0;
    height: 0;
    left: -105px;
    border-left: 78px solid transparent;
    border-right: 29px solid #3a4242;
    border-top: 80px solid transparent;
    pointer-events: none;
}

section.app-features.light .app-feature-item {
    background-color: #D9D9D9;
}

section.app-features.light .app-feature-item img {
    filter: brightness(0);
}

section.app-features.light .app-feature-item h3 {
    color: #3D3D3D;
}

section.app-features.light .app-feature-item p {
    color: #616161;
}

@media(max-width:479.99px) {

    .app-feature-list .owl-nav .left-arrow,
    .app-feature-list .owl-nav .right-arrow {
        height: 80px;
        width: 100px;
    }
}