
.dark.flexibletransportclients{
    background: linear-gradient(to top, #191919 40%, #303030 60%);
    background: -webkit-linear-gradient(to top, #191919, #191919 29%, rgba(0, 0, 0, 0) 18%), -webkit-linear-gradient(-90deg, #191919 29%, #303030 16%);
    background: -o-linear-gradient(to top, #191919, #191919 29%, rgba(0, 0, 0, 0) 18%), -webkit-linear-gradient(-90deg, #191919 29%, #303030 16%);
    background: -moz-linear-gradient(to top, #191919, #191919 29%, rgba(0, 0, 0, 0) 18%), -webkit-linear-gradient(-90deg, #191919 29%, #303030 16%);
    background: linear-gradient(to top, #191919, #191919 29%, rgba(0, 0, 0, 0) 18%), -webkit-linear-gradient(-90deg, #191919 29%, #303030 16%);
    position: relative;
    z-index: 1;
}
.dark.flexibletransportclients::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 40%;
    background-color: #191919;
    top: 0;
    z-index: -1;
    left: 0;
}
.light.flexibletransportclients::after{

    background-color: #fff;
}
.light.flexibleTransportVendor{
    background: linear-gradient(to top, #fff 65%, #fff 35%);
}
.flexibletransportclients  h2.subtitle span {
    display: block;
    
}
.flexibletransportclients  img {
    width: 100%;
}