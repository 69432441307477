.fagpage-min{
    padding:100px 0px 50px;
}

.fagpage-min button.accordion-button {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    background: transparent !important;
    box-shadow: none;
    outline: none;
    padding-left: 100px;
}
.fagpage-min button.accordion-button::after{
    display: none;
}
.fagpage-min button.accordion-button::before {
    content: '-';
    position: absolute;
    left: 0;
    font-weight: 700;
    font-size: 35px;
    color: #47c11b;
    width: 50px;
    /* background: #000; */
    text-align: center;
}
.fagpage-min button.accordion-button:not(.collapsed):before {
    content: '+';
}
.fagpage-min .accordion-item{
    background: transparent;

}
.fagpage-min  .accordion-body{
    padding:20px 150px;
}

.fagpage-min .faq-accordion{
    min-height: 400px;
}


.faq-tab-btn .nav-item {
    width: 50%;
    text-align: center;
}
.faq-tab-btn .nav-item a{
   
   border-radius: 0px;
   font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 49.5px;
color: #FFF;
background-color: #3F3F3F;
}
.faq-tab-btn .nav-item a.active{
    color: #3F3F3F;
    background-color: #D9D9D9;
    font-weight: 700;
}

@media(max-width:574.99px){
    .fagpage-min button.accordion-button {
        font-size: 20px;
        line-height: 30px;
        padding-left: 70px;
    }
    .fagpage-min  .accordion-body {
        padding: 20px 0px 20px 72px;
    }

    .faq-tab-btn .nav-item a {
        /* font-size: 18px; */
        line-height: inherit;
    }
}