.Clientfeatures-list .card{
    border-radius: 0px;
    background-color:#191919;
    color: #fff;
}
.Clientfeatures-list .card h3{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.5px;
    margin-bottom: 12px;
}
.Clientfeatures-list .card p{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.Clientfeatures-list .card img{
    width: 45px;
    height: 45px;
    object-fit: contain;
    margin-bottom: 36px;
}
.Clientfeatures-list  .list-card .card{
    border-left: 1px solid #CACACA;
    padding: 20px;
}
.Clientfeatures-list  .list-card:nth-child(1) .card{
border:none;
}