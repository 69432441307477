.dark.whyvendorchoose {
    background: #191919;
}

.light.whyvendorchoose {
    background: #ffffff;
}

section.whyvendorchoose button.btn.btn-primary {
    width: 160px;
    border-radius: 0px;

    border: none;
    padding: 10px;
    font-weight: 500;
}

.whyvendorchoose h2.subtitle {
    max-width: 380px;
}

section.whyvendorchoose.dark button.btn.btn-primary {
    background: #fff;
    color: #3e3e3e;
}

section.whyvendorchoose.light button.btn.btn-primary {
    background: #3e3e3e;
    color: #fff;
}

.mt-150 {
    margin-top: 150px;
}

.whyvendorchoose .card {
    background: #303030;
    border-radius: 0px;
    padding: 20px;
    max-width: 300px;
    margin: 12px;
    text-align: left;

}

.whyvendorchoose .card img {
    width: 45px;
}

.whyvendorchoose .card h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.5px;
    color: #fff;
}

.whyvendorchoose .card p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
}

.Whychoose-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.Whychoose-list .card:nth-child(1) {
    margin-top: 150px;
}

.Whychoose-list .card:nth-child(4) {
    margin-top: -130px;
}

.light.whyvendorchoose .Whychoose-list .card img {
    filter: brightness(0);
}

.light.whyvendorchoose .Whychoose-list .card {
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
}

.light.whyvendorchoose .Whychoose-list .card h3 {
    color: #3d3d3d;
}

.light.whyvendorchoose .Whychoose-list .card p {
    color: #616161;
}

@media(max-width:1199.99px) {
    .whyvendorchoose .card {
        max-width: 260px;
    }
}

@media(max-width:767.99px) {
    .whyvendorchoose .card {
        max-width: 230px;
    }

    .mt-150 {
        margin-top: 10px;
    }
}

@media(max-width:574.99px) {
    .whyvendorchoose .card {
        max-width: 200px;
    }
}

@media(max-width:479.9px) {
    .Whychoose-list .card:nth-child(1) {
        margin-top: 0;
    }

    .Whychoose-list .card:nth-child(4) {
        margin-top: 0px;
    }
}