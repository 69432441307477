.homeblog {
    position: relative;
    padding-top: 150px;
    background-image: url(../../../assets/images/bg/blog-bg.png);
    background-repeat: no-repeat;
    background-position: 50% 75%;

}

span.scroll-down {
    position: absolute;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: 56px;
    /* 200% */
    letter-spacing: -0.28px;
    color: #fff;
    transform: rotate(90deg);
    right: 8%;
    top: 50px;
}

span.scroll-down::after {
    position: absolute;
    content: '';
    height: 2px;
    right: -59px;
    bottom: 24px;
    width: 48px;
    background-color: #fff;
}

.homeblog h3 {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 49.5px;
}

.homeblog h3~p {
    max-width: 500px;
    margin: 12px auto 30px;
}

button.blog-btn {
    border-radius: 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 177.778% */
    letter-spacing: -0.18px;
    border: none;
    outline: none;
    box-shadow: none;
}

.dark button.blog-btn:hover,
.dark button.blog-btn:focus,
.dark button.blog-btn {
    background: #fff;
    color: #3E3E3E;
}

.light button.blog-btn:hover,
.light button.blog-btn:focus,
.light button.blog-btn {
    background: #616161;
    color: #fff;
}


.blog-item-list {
    width: 100%;
    display: flex;
    position: relative;
    bottom: -120px;
    z-index: 1;
}

.blog-item {
    position: relative;
    width: calc(100% / 3);
}

.blog-item-list .blog-item:nth-child(2) {
    bottom: -80px;
}

.blog-item-list img {
    width: 100%;
    display: flex;
    position: relative;
    height: 100%;
    object-fit: cover;
}

.blog-info {
    position: absolute;
    bottom: 0;
    padding: 24px 12px 12px 12px;
    color: #fff;
    width: 100%;
}

.blog-info h5 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 106.667% */
    letter-spacing: -0.3px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-info p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.5px;
    /* 171.875% */
    letter-spacing: -0.16px;
    margin: 0px;
    padding-right: 60px;
}

.blog-info p span {
    margin-right: 12px;
    display: inline-block;
}

.blog-item-list .blog-item .blog-info {
    background-color: rgba(46, 46, 46, 0.5);
}

.blog-item-list .blog-item.active .blog-info,
.blog-item-list .blog-item:hover .blog-info {
    background-color: rgba(46, 46, 46, 0.9);
}


.blog-item a.betails {
    width: 60px;
    padding: 10px;
    position: absolute;
    background: #fff;
    border-radius: 50px;
    bottom: 10px;
    right: 10px;
    text-align: center;
    opacity: 0;
}

.blog-item a.betails img {
    width: 20px;
    filter: brightness(0.5);
    margin: 0 auto;
}

.blog-item-list .blog-item.active a.betails,
.blog-item-list .blog-item:hover a.betails {
    opacity: 1;
}

@media(max-width:767.99px) {
    .blog-info h5 {
        font-size: 20px;
        line-height: 22px;
    }

    .blog-info p {
        font-size: 10px;
        line-height: 17.5px;
    }

    .homeblog {
        padding-top: 0;
    }

    span.scroll-down {

        top: -150px;
    }
}

@media(max-width:479.99px) {
    .blog-item {
        width: calc(100% / 1);
        margin-bottom: 20px !important;
    }

    .blog-item-list {
        bottom: -40px;
        flex-wrap: wrap;
    }

    .blog-item-list .blog-item:nth-child(2) {
        bottom: 0px;
    }
}