.servicespageinfo{
    position: relative;
    padding: 100px 0px 50px 0px;
}
ul.serviceslist {
    width: 100%;
    display: block;
}

ul.serviceslist li{
   display: flex;
   align-items: center;
   padding: 10px !important;
    border-radius: 8px;
    margin-bottom: 50px;
    position: relative;
}
ul.serviceslist li.active{
    border: 1px solid;
}
.srl-icon {
    width: 65px;
    text-align: center;
}
.srl-cont {
    width: calc(100% - 65px);
}
.srl-cont h2{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
}
.srl-cont p{
    color: #6D6D6D;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 27.5px; /* 171.875% */
    margin-bottom: 0px;
}

ul.serviceslist li::after{
    content: '';
    position: absolute;
    height: 50px;
    border-left: 1px dotted #fff;
    top: -46px;
    left: 42px;
}
ul.serviceslist li:nth-child(1)::after{
    display: none;
}

.light ul.serviceslist li{
    /* background-color: #f2f2f2; */
}
ul.serviceslist li::after{
    /* border: 1px dotted #f2f2f2; */
}
.light ul.serviceslist li img{
    filter: brightness(0.2);
}