.inner-banner{
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
}
.min-height-450{
    min-height: 450px ;
}
.banner-container{
    height: 100%;
}
.banner-container h2{
    color: #FFF;
    font-size: 45.475px;
    font-style: normal;
    font-weight: 600;
    line-height: 53.772px;
    letter-spacing: -0.455px;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
}
.banner-container  span{
    font-weight: 300;
}
.banner-container h2:after {
    content: '';
    height: 145px;
    width: 62px;
    background: transparent;
    position: absolute;
    top: calc(50% - 72px);
    left: -27px;
    z-index: -1;
    background-image: url(../../assets/images/shap.png);
}

@media(max-width:767.99px){
    .min-height-450{
        min-height: 370px ;
    }
    .banner-container h2 {
        font-size: 30.475px;
        line-height: 43.772px;
    }
    .banner-container h2:after {
        height: 100px;
        top: calc(50% - 50px);
         background-size: contain;
        background-repeat: no-repeat;
    }
}