.Servicesage span.counter {
    display: none;
    padding-bottom: 10;
}
.Servicesage {
    padding-bottom: 200px !important;
}

.Servicesagegetintuch span.count-getinTouch {
    display: none;
}

@media(max-width:991.99px){
    .Servicesage {
        overflow: hidden;
    }
    .Servicesage {
        padding-bottom: 42px !important;
    }
}

@media(max-width:767.99px){
    .Servicesage {
        overflow: hidden;
    }
    .Servicesage {
        padding-bottom: 42px !important;
    }
    ul.serviceslist li:last-child {
        margin-bottom: 0px;
    }
    .servicespageinfo {
        position: relative;
        padding: 45px 0px 0px 0px;
    }
}