.dark.flexibleTransportVendor{
    background: linear-gradient(to top, #191919 65%, #303030 35%);
}
.light.flexibleTransportVendor{
    background: linear-gradient(to top, #fff 65%, #fff 35%);
}
.ftv-img img{
    max-width: 100%;
}
@media(max-width:991.99px){
    .flexibletransportclients .align-items-center.row {
        flex-direction: column-reverse;
    }
}