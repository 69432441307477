body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 797.99px) {
.whyvendorchoose .card {
    max-width: 100% !important;
}
.Whychoose-list .card:nth-child(1) {
  margin-top: 0!important;
}
.Clientfeatures-list .list-card .card {
  border-left: 0px!important;
}
section.back-color-white.min-contain.light {
  padding: 100px 0px 40px;
}
}

@media (max-width: 767.99px) {
.modffrom_section .col {
  width: 100%;
  max-width: 100%;
  flex: 100%;
}
section.requestform-app.modffrom_section img {
  max-width: 243px;
}

section.requestform-app.modffrom_section img {
  top: -103px;
}
section.requestform-app.py-5.dark.modffrom_section {
  padding-top: 5rem !important;
}   
}