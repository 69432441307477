.GetinTouchblogpage span.count-getinTouch{
    display: none;
}
.bloglisting{
    padding: 100px 0px;
}

.blog-item.card {
    border-radius: 0px;
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover !important;
    color: #fff;
    position: relative;
    z-index: 1;
    background: transparent;
    border: none;
}
.blog-item.card::after{
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 0;
}
.blog-item-info {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    padding: 10px;
    cursor: pointer;
}
.news-list.card h3,
.blog-mid-item.card h3,
.blog-item-info  h3{
    font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 145.455% */
letter-spacing: -0.22px;
}
.news-list.card p,
.blog-mid-item.card p,
.blog-item-info  p{
    font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 27.5px; /* 171.875% */
letter-spacing: -0.16px;
margin-bottom: 0px;
}
.blog-item-info  p span{
    margin-right: 12px;
}

.blog-mid-item.card {
    border: 0px;
    border-radius: 0px;
    color: #fff;
    background: transparent;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 20px;
}
.blog-mid-item.card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 20px;
}

.news-list.card {
    flex-direction: revert;
    border: none;
    background: transparent;
    color: #fff;
    align-items: center;
}
.news-list.card small{
    font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
letter-spacing: -0.16px;
text-transform: uppercase;
filter: brightness(0.8);
}
.news-list.card 
 .news-list-img {
    width: 160px;
}
.news-list.card 
 .news-list-img img{
    width: 100%;
}
.news-contain {
    width: calc(100% - 160px);
}
.news-right .news-list.card {
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0px;
    padding-bottom: 16px;
}
.bigblog.blog-item.mb-3.card {
    min-height: 720px;
}


.botto-rightlist .news-list.card 
.news-list-img {
   width: 230px;
}
.botto-rightlist .news-contain {
    width: calc(100% - 230px);
}
.botto-rightlist .news-list.card {
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0px;
    padding-bottom: 24px;
}
.news-list.card:nth-last-child(1){
    border: none;
}

/* blogdetails */
.background-color-gray {
    padding: 150px 0px 50px;
    background-color: #191919 !important;
}
.blog-title{
    font-size: 52px;
font-style: normal;
font-weight: 600;
line-height: 64px; /* 123.077% */
letter-spacing: -0.52px;
}
.profile-info-wrap {
    display: flex;
    align-items: center;
}
.profile-info {
    display: flex;
    justify-content: end;
}
.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 12px;
}
.profile-pic img{
    width: 100%;
    height: 100%;
}
.profile-info.text-color-gray p{
    margin: 0px;
}
span.frist-word {
    font-size: 61.633px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.616px;
    display: inline-block;
    float: left;
    margin-right: 24px;
}
.blog-detais-info p {
    font-size: 20px;
    font-weight: 300;
}
.blog-detais-info {
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.blogdetails {
    padding: 50px 0px 150px;
}

.gt-blog-details span.count-getinTouch {
    display: none;
}

.light .news-list.card h3,
.light  .blog-mid-item.card h3,
.light  .news-list.card p,
.light  .blog-mid-item.card p
{
    color: #616161;
}

@media(max-width:1199.99px){
    .news-contain {
        width: calc(100% - 120px);
    }
    .news-list.card  .news-list-img {
    width: 120px;
}
.news-list.card h3, .blog-mid-item.card h3, .blog-item-info  h3 {
    font-size: 16px;
    line-height: 24px;
}
}


@media(max-width:767.99px){
    .blog-detais-info p {
        font-size: 16px;
    }
    .blog-title {
        font-size: 28px;
        line-height: 47px;
        margin-bottom: 29px;
    }
    .blogdetails {
        padding: 40px 0px 100px;
    }
}
@media(max-width:574.99px){
    .bigblog.blog-item.mb-3.card {
        min-height: auto;
    }
    .botto-rightlist .news-list.card 
.news-list-img {
    width: 160px;
}
.botto-rightlist .news-contain {
    width: calc(100% - 160px);
}
}