.download-apap{
    position: relative;
    padding-top: 100px;
    padding-bottom: 50px;
    background-image: url(../../assets/images/bg/Download.png);
    background-position: 0% 10%;
    background-repeat: no-repeat;
}
.phone-demo{
    background-image: url(../../assets/images/bg/phone-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
}
@media(max-width:1199.99px){
    .phone-demo img {
        max-width: 100%;
    }
}