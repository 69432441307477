.scroll-to-top.visible {
    position: fixed;
    z-index: 999;
    bottom: 50px;
    right: 50px;
}
.scroll-btn:hover, .scroll-btn:focus,
.scroll-btn{
width: 60px;
height: 60px;
line-height: 60px;
text-align: center;
border: none;
outline: none;
box-shadow: none;
cursor: pointer;
padding: 0px;
}

.dark.scroll-btn:hover,
.dark.scroll-btn:focus,
.dark.scroll-btn{
    background: #1D1D1D;
}
.light.scroll-btn:hover,
.light.scroll-btn:focus,
.light.scroll-btn{
    background: #616161;
}