.home-min-banner .carousel-caption {
  right: auto;
  top: 350px;
  bottom: auto;
  text-align: left;
  left: 10%;
}
.min-banner {
  position: relative;
}
.home-min-banner .carousel-caption h3 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  min-height: 200px;
  max-width: 306px;
}
.home-min-banner .carousel-caption a {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.18px;
  background: #fff;
  padding: 8px 20px;
  color: #191919;
  margin-top: 30px;
  display: inline-block;
}
.home-min-banner a.carousel-control-next span,
.home-min-banner a.carousel-control-prev span {
  display: none;
}
.home-min-banner a.carousel-control-next,
.home-min-banner a.carousel-control-prev {
  bottom: 0;
  height: 80px;
  width: 150px;
  top: auto;
  left: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}
.home-min-banner a.carousel-control-next {
  right: 0px;
  background-image: url(../../../assets/images/icon/arrow-right.svg);
  background-color: #3a4242;
}
.home-min-banner a.carousel-control-prev {
  right: 150px;
  background-image: url(../../../assets/images/icon/arrow-left.svg);
}
.home-min-banner a.carousel-control-next:after {
  content: "";
  position: absolute;
  top: 0;
  background: transparent;
  width: 0;
  height: 0;
  left: -105px;
  border-left: 78px solid transparent;
  border-right: 29px solid #3a4242;
  border-top: 80px solid transparent;
}
.home-min-banner img {
  max-height: 100vh;
  object-fit: cover;
  min-height: 780px;
}
.home-min-banner .carousel-indicators {
  right: auto;
  left: 10%;
  margin-left: 0;
  display: none;
}

ul.banner-count {
  position: absolute;
  bottom: 20px;
  z-index: 1;
  left: 10%;
}
ul.banner-count li {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  color: #fff;

  padding: 10px 5px !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}
ul.banner-count li.active {
  border-bottom: 4px solid #fff;
  width: 50px;
}
span.blink-tab {
  cursor: pointer;
  position: absolute;
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50px;
  bottom: 20px;
  z-index: 1;
  left: calc(50%);
}
span.blink-tab:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 20px;
  border-radius: 50px;
  background: #fff;
  top: 4px;
  left: calc(50% - 2px);
}

@media (max-width: 574.99px) {
  .home-min-banner .carousel-caption h3 {
    font-size: 40px;
    line-height: 50px;
    max-width: 400px;
  }
  span.blink-tab {
    display: none;
  }
  .home-min-banner img {
    max-height: 100vh;
    object-fit: cover;
    min-height: 600px;
  }
  .home-min-banner .carousel-caption {
    top: 200px;
  }
}
@media (max-width: 479.99px) {
  ul.banner-count {
    display: none;
  }
  .home-min-banner a.carousel-control-next,
  .home-min-banner a.carousel-control-prev {
    width: 90px;
  }
}
