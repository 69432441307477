.solution-slider button.owl-dot span {
    width: 120px !important;
    border-radius: 0px !important;
    height: 6px !important;

}

.solution-slider .owl-dots {
    margin-top: 50px !important;
}

.solution-item {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #2F2F2F; */
    background-size: cover;
    background-position: bottom center;
}

.solution-item button.btn.btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #302922;
    border: 2px solid #fff;
    border-radius: 0px;
    padding: 8px 24px;
}

.solution-item button.btn.btn-primary img {
    width: 20px;
    margin-left: 8px;

}

.solution-item button.btn.btn-primary {
    opacity: 0;
    transition: all 0.8ms;
}

.owl-item.active.center .solution-item button.btn.btn-primary {
    opacity: 1;
    transition: all 2ms;
}

@media(max-width:767.99px) {
    .solution-slider button.owl-dot span {
        width: 10px !important;
    }
}

@media(max-width:479.99px) {
    .solution-item {
        height: 300px;
    }
}