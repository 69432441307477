.dark.back-Discount {
    background-color: #3e3e3e;
    background-image: url(../../assets/images/bg/discountbg.png), url(../../assets/images/bg/discount.png);

    background-repeat: no-repeat;
    background-size: cover, auto;
    background-position: center, 80% 40%;
}

.back-Discount {
    padding-top: 400px;
    padding-bottom: 200px;
    min-height: 600px;
    position: relative;
}

.Discountinfo {
    max-width: 835px;
    min-height: 325px;
    margin: 0 auto;
    padding: 50px;
    background-image: url(../../assets/images/bg/discount-border.svg);
    position: relative;
}

.Discountinfo h3 {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 58.443px;
    color: #fff;
}

.Discountinfo h3 span {
    font-weight: 700;
}

.Discountinfo p {
    max-width: 50%;
    color: #fff;
}

.Discountinfo button.btn.btn-primary:hover,
.Discountinfo button.btn.btn-primary:focus,
.Discountinfo button.btn.btn-primary {
    background: #fff;
    border-radius: 0px;
    border: none;
    color: #3e3e3e !important;
    padding: 10px 20px;
    font-weight: 500;
}

img.dis-car {
    position: absolute;
    right: -130px;
    bottom: -125px;
}

span.count-down {
    position: absolute;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 55.172% */
    letter-spacing: -0.58px;
    color: #fff;
    transform: rotate(-90deg);
    right: 12%;
    top: 50%;
    filter: brightness(0.5);
}

span.count-down::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 74px;
    bottom: 14px;
    width: 48px;
    background-color: #fff;
}

.light .Discountinfo button.btn.btn-primary:hover,
.light .Discountinfo button.btn.btn-primary:focus,
.light .Discountinfo button.btn.btn-primary {
    background: #616161;

    color: #fff !important;
}

.light .Discountinfo h3 {
    color: #3d3d3d;
}

.light .Discountinfo p {
    color: #616161;
}

.light.back-Discount {
    background-color: #d9d9d9;
    background-image: url(../../assets/images/bg/app-feature-item-bg.png);

    background-size: cover, ;
}

@media(max-width:1199.99px) {
    img.dis-car {
        right: -95px;
        max-width: 450px;
    }

}

@media(max-width:991.99px) {
    img.dis-car {
        right: -14px;
        max-width: 325px;
        bottom: -45px;
    }

    .Discountinfo {
        background-repeat: no-repeat;
        background-size: contain;
    }

    img.slf2 {
        display: none;
    }
}

@media(max-width:767.99px) {
    .Discountinfo h3 {
        font-size: 32px;
        line-height: 36.443px;
        max-width: 80%;
    }

    .Discountinfo p {
        max-width: 80%;
    }

    .Discountinfo {
        min-height: 300px;
        padding: 13px 50px;
    }

    img.dis-car {
        right: 0;
        max-width: 280px;
        bottom: -20px;
    }
}

@media(max-width:479.99px) {
    .back-Discount {
        padding-top: 100px;
        padding-bottom: 50px;
        min-height: auto;
    }

    .Discountinfo h3 {
        font-size: 16px;
        line-height: 22.443px;
        max-width: 90%;
    }

    .Discountinfo {
        min-height: 300px;
        padding: 13px 14px;
    }

    .Discountinfo p {
        max-width: 100%;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0px;
    }

    .Discountinfo button.btn.btn-primary:hover,
    .Discountinfo button.btn.btn-primary:focus,
    .Discountinfo button.btn.btn-primary {

        padding: 4px 10px;
        font-size: 10px;
    }

    img.dis-car {
        right: 0;
        max-width: 150px;
        bottom: 0;
    }

    span.count-down {
        display: none;
    }

    .Discountinfo {
        min-height: 175px;
        padding: 13px 14px;
    }
}