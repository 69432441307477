.homefag-min{
    position: relative;
    padding-bottom: 150px;
}
.homefag-min button.accordion-button {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    background: transparent !important;
    box-shadow: none;
    outline: none;
    padding-left: 100px;
}
.homefag-min button.accordion-button::after{
    display: none;
}
.homefag-min button.accordion-button::before {
    content: '-';
    position: absolute;
    left: 0;
    font-weight: 700;
    font-size: 35px;
    color: #47c11b;
    width: 50px;
    /* background: #000; */
    text-align: center;
}
.homefag-min button.accordion-button:not(.collapsed):before {
    content: '+';
}
.homefag-min .accordion-item{
    background: transparent;

}
.homefag-min  .accordion-body{
    padding:20px 150px;
}
.faq-accordion .accordion-body p{
    padding-left: 10px;
    border-left: 3px solid  #47C11B;
}
.dark .faq-accordion .accordion-body p,
.dark .faq-accordion button.accordion-button{
    color: #fff;
}
.light .faq-accordion .accordion-body p,
.light .faq-accordion button.accordion-button{
    color: #616161;
}

@media(max-width:574.99px){
    .homefag-min  .accordion-body {
        padding: 20px 0px 20px 100px;
    }
    /* .homefag-min button.accordion-button {
        font-size: 22px;
        line-height: 30px;
        padding-left: 70px;
    } */
    .homefag-min button.accordion-button {
        font-size: 16px;
        line-height: 24px;
        padding-left: 51px;
    }
}