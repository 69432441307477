.dark.getinTouch-app {
    background-color: #352E2E;
}

.light.getinTouch-app {
    background-color: #d9d9d9;
}

.getinTouch-app {
    position: relative;
}

.getinTouch-app .form-control {
    border-radius: 0px;
    margin-bottom: 30px !important;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    min-height: 48px;
    padding-left: 0px;
}

.dark.getinTouch-app .form-control {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.light.getinTouch-app .form-control {
    color: #616161;
    border-bottom: 1px solid #616161;
}

.dark.getinTouch-app .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #fff;
}

.dark.getinTouch-app .form-control::placeholder {
    color: #fff;
}

.light.getinTouch-app .form-control::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #616161;
}

.light.getinTouch-app .form-control::placeholder {
    color: #616161;
}

section.getinTouch-app img {
    position: absolute;
    top: -100px;
    right: 0;
}

section.getinTouch-app button.btn.btn-primary {
    width: 160px;
    border-radius: 0px;

    border: none;
    padding: 10px;
    font-weight: 500;
}

section.getinTouch-app.dark button.btn.btn-primary {
    background: #fff;
    color: #3e3e3e;
}

section.getinTouch-app.light button.btn.btn-primary {
    background: #3e3e3e;
    color: #fff;
}


span.count-getinTouch {
    position: absolute;
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 55.172% */
    letter-spacing: -0.58px;
    color: #fff;
    transform: rotate(-90deg);
    right: 12%;
    bottom: 100px;
    z-index: 9;
    filter: brightness(0.5);
}

span.count-getinTouch::after {
    position: absolute;
    content: '';
    height: 2px;
    right: 74px;
    bottom: 14px;
    width: 48px;
    background-color: #fff;
}

@media(max-width:1079.99px) {
    section.getinTouch-app img {
        max-width: 500px;
    }
}

@media(max-width:991.99px) {
    section.getinTouch-app img {
        max-width: 400px;
    }
}

@media(max-width:767.99px) {
    section.getinTouch-app img {
        top: -50px;
        width: 300px;
    }
}

@media(max-width:574.99px) {
    section.getinTouch-app img {
        top: -30px;
        width: 230px;
    }
}

@media(max-width:479.99px) {
    section.getinTouch-app img {
        top: -30px;
        width: 180px;
    }

    section.getinTouch-app .col {
        min-width: 100% !important;
    }
}